import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";

import Logo from "../../components/logo/Logo";
import { useAuthRepository } from "../../repositories/auth/authRepository";

const navItems = [
  { label: "Buscador", to: "/" },
  {
    label: "Subir ejercicios",
    to: "/upload-exercises",
  },
];

function Layout() {
  const { user, authRepository } = useAuthRepository();

  return (
    <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 flex flex-col">
      <header className="flex justify-between border-b border-gray-200 pt-6 pb-4 sm:pt-10 items-end">
        <Logo label="Explorer" />
        <nav className="flex gap-4">
          {user.role === "admin" &&
            navItems.map((item) => (
              <NavLink
                className={({ isActive }) =>
                  clsx(
                    "hover:bg-gray-100 rounded-md px-3 py-2 text-sm font-medium",
                    {
                      "bg-gray-100": isActive,
                    }
                  )
                }
                key={item.label}
                to={item.to}
              >
                {item.label}
              </NavLink>
            ))}
          <button
            onClick={authRepository.logout}
            className="inline-flex w-auto justify-center rounded-md hover:bg-red-600 px-3 py-2 text-sm font-semibold text-red-600 hover:text-white"
          >
            Cerrar sesión
          </button>
        </nav>
      </header>
      <main className="pt-6 pb-4 lg:pb-6 flex flex-1">
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
