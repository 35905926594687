import { useStore } from "@nanostores/react";
import { useEffect, useState } from "react";

import { useExercisesRepository } from "../exercises/exercisesRepository";

import AuthRepositoryFirebase from "./AuthRepositoryFirebase";

const authRepositoryFirebase = new AuthRepositoryFirebase();

function useAuthRepository() {
  const [authRepository] = useState(authRepositoryFirebase);
  const user = useStore(authRepository.stores.user);
  const { exercisesRepository } = useExercisesRepository();

  useEffect(() => {
    if (user?.uid) {
      // TODO: Thing about this, it's not the best place to load exercises
      // because it will get called every where the hook is used
      exercisesRepository.loadExercises();
    }
  }, [user?.uid]);

  return { user, authRepository };
}

export { useAuthRepository };
