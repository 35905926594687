import { Navigate } from "react-router-dom";

import { useAuthRepository } from "../../repositories/auth/authRepository";

function RequireAuth({ children }) {
  const { user } = useAuthRepository();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default RequireAuth;
