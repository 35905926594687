import { ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useRef } from "react";

import noImageSrc from "../../../assets/no-image.jpg";
import placeholderSrc from "../../../assets/placeholder.jpg";

function ExercisesGrid({ exercises, viewMode }) {
  const imagesLoadedRef = useRef({});
  const observerRef = useRef();

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const exercise = entry.target;
            const { id, src } = exercise.dataset;

            if (src) {
              exercise.style.backgroundImage = `url(${src})`;
            }

            imagesLoadedRef.current[id] = true;

            exercise.removeAttribute("data-src");

            observerRef.current.unobserve(exercise);
          }
        });
      },
      { rootMargin: "250px 0px 250px 0px" }
    );

    return () => observerRef.current.disconnect();
  }, []);

  useEffect(() => {
    const images = document.querySelectorAll(`.exercise[data-src]`);

    images.forEach((img) => {
      observerRef.current?.observe(img);
    });

    const exerciseIds = exercises.map(({ id }) => id);

    Object.keys(imagesLoadedRef.current).forEach((id) => {
      if (!exerciseIds.includes(id)) {
        imagesLoadedRef.current[id] = false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exercises.length]);

  return (
    <div
      className={clsx([
        "grid grid-cols-1 gap-y-10 gap-x-6 xl:gap-x-8 flex-1",
        {
          "sm:grid-cols-2 lg:grid-cols-3": viewMode === "grid",
        },
      ])}
    >
      {exercises.map((exercise, index) => {
        const preloadImages = 6;

        if (index < preloadImages && !imagesLoadedRef.current[exercise.id]) {
          imagesLoadedRef.current[exercise.id] = true;
        }

        const imageSrc = exercise.image || noImageSrc;
        const backgroundImage = imagesLoadedRef.current[exercise.id]
          ? imageSrc
          : placeholderSrc;
        const toLoadImage = !imagesLoadedRef.current[exercise.id]
          ? imageSrc
          : null;
        const isWideExercise = exercise.material?.includes("reformer");

        return (
          <article key={exercise.id}>
            <div
              className={clsx(
                "exercise w-full overflow-hidden rounded-lg bg-gray-100 relative bg-cover bg-center bg-blend-multiply bg-no-repeat",
                {
                  "lg:aspect-w-7 lg:aspect-h-8": viewMode === "grid",
                  "bg-contain": isWideExercise,
                }
              )}
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: isWideExercise ? "140%" : "inherit",
              }}
              data-id={exercise.id}
              data-src={toLoadImage}
            >
              <h4 className="absolute flex justify-start align-top py-2 px-3 text-gray-600 font-bold tracking-tight">
                #{exercise.id}
              </h4>
            </div>
            <h3 className="mt-4 text-sm text-gray-700 uppercase">
              {exercise.description}
            </h3>
            <p className="mt-1 text-xs text-gray-500 capitalize ">
              {[exercise.muscleGroup, exercise.material]
                .flat()
                .map((value) => value?.toLowerCase())
                .join(" · ")}
            </p>
          </article>
        );
      })}

      <button
        className="fixed bottom-10 right-10 bg-gray-100 rounded-full px-3 py-2 text-gray-600 font-bold flex items-center pl-5 gap-1 shadow-[0px_0px_4px_1px_#DBDBDB]"
        onClick={() =>
          document
            .querySelector("#root")
            .scrollTo({ top: 0, behavior: "smooth" })
        }
      >
        {exercises.length}
        <ChevronUpIcon className="w-8 h-8" />
      </button>
    </div>
  );
}

export default ExercisesGrid;
