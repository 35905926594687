import Spinner from "../../components/loader/Spinner";

import Exercises from "./exercises/Exercises";
import Filters from "./filters/Filters";
import { useSearchExercises } from "./useSearchExercises";

function SearchExercises() {
  const { loading, filters, setFilter, exercises, filteredExercises, options } =
    useSearchExercises();

  return (
    <div className="gap-x-8 gap-y-10 flex flex-1">
      <aside className="hidden lg:block w-72">
        <Filters
          filters={filters}
          onFilterChange={setFilter}
          preview={loading}
        />
      </aside>

      <section className="rounded-lg flex-1">
        {loading ? (
          <div className="grid place-content-center p-4">
            <Spinner />
          </div>
        ) : (
          <Exercises
            exercises={exercises}
            filteredExercises={filteredExercises}
            viewMode={options.viewMode}
          />
        )}
      </section>
    </div>
  );
}

export default SearchExercises;
