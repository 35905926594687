function arrayParser(value) {
  const separatorRegExp = new RegExp(",|;");
  if (value.match(separatorRegExp)) {
    return value
      .split(separatorRegExp)
      .map((item) => item.toLowerCase().trim());
  }

  return [value.toLowerCase()];
}

const exercisesSchema = {
  EJERCICIO: {
    name: "Número de ejercicio",
    prop: "id",
    type: String,
    required: true,
    filterType: "number",
    showInPreview: true,
  },
  DESCRIPCIÓN: {
    prop: "description",
    type: (value) => value.toLowerCase(),
    required: true,
    filterType: "text",
    showInPreview: true,
  },
  MATERIAL: {
    prop: "material",
    type: arrayParser,
    filterType: "array",
    showInPreview: true,
  },
  "GRUPO MUSCULAR": {
    prop: "muscleGroup",
    type: arrayParser,
    filterType: "array",
    showInPreview: true,
  },
  COMPLEX: {
    prop: "complexity",
    type: arrayParser,
    filterType: "array",
  },
  "TIPO EJERCICIO": {
    prop: "type",
    type: arrayParser,
    filterType: "array",
    showInPreview: true,
  },
  GRABADO: {
    prop: "recorded",
    type: arrayParser,
    filterType: "array",
  },
  ENTRENADOR: {
    prop: "trainer",
    type: arrayParser,
    filterType: "array",
  },
  IMAGEN: {
    prop: "image",
    type: String,
  },
};

const filterableProperties = Object.entries(exercisesSchema)
  .filter(([, { filterType }]) => filterType)
  .map(([key, { name, prop: id, showInPreview, filterType }]) => ({
    id,
    name: name || key.toLowerCase(),
    filterType,
    showInPreview: Boolean(showInPreview),
  }));

export { filterableProperties };

export default exercisesSchema;
