import ArrayFilter from "./ArrayFilter";
import NumberFilter from "./NumberFilter";
import TextFilter from "./TextFilter";

function Filters({ filters, onFilterChange, preview }) {
  return (
    <form>
      {filters.map((section) => {
        if (preview && !section.showInPreview) {
          return undefined;
        }

        if (section.filterType === "number") {
          return (
            <NumberFilter
              key={section.id}
              id={section.id}
              label={section.name}
              disabled={preview}
              value={section.value}
              onChange={(value) => onFilterChange(section.id, value)}
            />
          );
        }

        if (section.filterType === "text") {
          return (
            <TextFilter
              key={section.id}
              id={section.id}
              label={section.name}
              disabled={preview}
              value={section.value}
              onChange={(value) => onFilterChange(section.id, value)}
            />
          );
        }

        if (section.filterType === "array") {
          if ((!section.options || section.options?.length <= 1) && !preview) {
            return undefined;
          }

          return (
            <ArrayFilter
              key={section.id}
              id={section.id}
              label={section.name}
              options={section.options}
              disabled={preview}
              value={section.value}
              onChange={(value) => onFilterChange(section.id, value)}
            />
          );
        }

        return undefined;
      })}
    </form>
  );
}

export default Filters;
