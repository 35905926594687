import ExercisesEmpty from "./ExercisesEmpty";
import ExercisesGrid from "./ExercisesGrid";
import ExercisesNotFound from "./ExercisesNotFound";

function Exercises({ exercises, filteredExercises, viewMode }) {
  if (!exercises || !exercises.length) {
    return <ExercisesEmpty />;
  }

  if (!filteredExercises.length) {
    return <ExercisesNotFound />;
  }

  return <ExercisesGrid exercises={filteredExercises} viewMode={viewMode} />;
}

export default Exercises;
