function Button({ as, ...props }) {
  const Component = as || "button";

  return (
    <Component
      className="block w-full mb-4 rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-gray-700 border border-gray-200 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40"
      {...props}
    />
  );
}

export default Button;
