import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthRepository } from "../../repositories/auth/authRepository";

import DecorativeImageGrid from "./DecorativeImageGrid";

function Login() {
  const { user, authRepository } = useAuthRepository();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
  }, [navigate, user]);

  return (
    <div className="relative overflow-visible max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 flex flex-col">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40 min-h-full flex flex-col justify-center">
        <div className="relative max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <div className="sm:max-w-lg">
            <h1 className="font text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Inciar sessión
            </h1>
            <p className="mt-8 text-xl text-gray-500">
              ¡Bienvenido a <strong>Bz55 Explorer</strong>! La plataforma
              integral para gestionar y optimizar rutinas de ejercicios. 💪
            </p>
          </div>
          <div className="mt-10">
            <button
              onClick={authRepository.loginWithGoogle}
              className="inline-block rounded-lg border border-transparent bg-blue-600 px-8 py-3 text-center font-medium text-white hover:bg-blue-700"
            >
              Continuar con Google
            </button>
          </div>
        </div>
      </div>

      <DecorativeImageGrid />
    </div>
  );
}

export default Login;
