import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

function ArrayFilter({ label, id, disabled, options, value = [], onChange }) {
  return (
    <Disclosure
      as="div"
      key={id}
      className="border-b border-gray-200 mt-6 pb-6 first:pt-0 last:border-none"
      defaultOpen
    >
      {({ open }) => (
        <>
          <h3 className="-my-3 flow-root">
            <Disclosure.Button
              className={clsx([
                "flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500",
                { "cursor-not-allowed": disabled },
              ])}
            >
              <span className="font-medium text-gray-900 capitalize">
                {label}
              </span>
              {!disabled && (
                <span className="ml-6 flex items-center">
                  {open ? (
                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              )}
            </Disclosure.Button>
          </h3>
          {!disabled && (
            <Disclosure.Panel className="mt-4 max-h-96 overflow-y-scroll p-1">
              <div className="space-y-4">
                {options?.map((option) => (
                  <label
                    key={option}
                    className="flex items-center cursor-pointer"
                  >
                    <input
                      name={`${id}[]`}
                      defaultValue={option}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer"
                      onChange={(event) => {
                        if (event.target.checked) {
                          onChange([...value, option]);
                        } else {
                          onChange(
                            value.filter((element) => element !== option)
                          );
                        }
                      }}
                    />
                    <span className="ml-3 text-sm text-gray-600 capitalize">
                      {option}
                    </span>
                  </label>
                ))}
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
}

export default ArrayFilter;
