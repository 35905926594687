import {
  ArrowDownTrayIcon,
  ArrowPathIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";

function Dropzone({
  title,
  message,
  loading,
  error,
  onSuccess,
  onFail,
  onReset,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onSuccess,
    onDropRejected: onFail,
    onDragEnter: onReset,
    disabled: loading,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlxs",
      ],
      "application/vnd.ms-excel": [".xls"],
      "image/gif": [".gif"],
      "image/webp": [".webp"],
    },
  });

  let Icon = ArrowUpTrayIcon;

  if (isDragActive) {
    Icon = ArrowDownTrayIcon;
  } else if (loading) {
    Icon = ArrowPathIcon;
  } else if (error) {
    Icon = ExclamationTriangleIcon;
  }

  return (
    <div
      className={clsx([
        "rounded-lg border-4 border-dashed lg:h-full flex-1 flex bg-gray-50 border-gray-200 text-gray-600 h-full",
        { "bg-red-50 border-red-200 text-red-600": error },
        { "bg-blue-50 border-blue-200 text-blue-600": isDragActive },
      ])}
    >
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center w-full p-5 text-centercursor-pointer justify-center align-middle cursor-pointer hover:opacity-70 transition-opacity"
        {...getRootProps()}
      >
        <Icon className="w-8 h-8" />

        <h2 className="mt-2 font-medium tracking-wide">{title}</h2>

        <p className="mt-2 text-xs tracking-wide max-w-md text-center">
          {message}
        </p>
      </label>
      <input
        {...getInputProps()}
        id="dropzone-file"
        type="file"
        className="hidden"
      />
    </div>
  );
}

export default Dropzone;
