import logoSrc from "../../assets/logo-small.png";

function Logo({ label }) {
  return (
    <h1 className="text-4xl font-bold tracking-tight text-gray-900 flex flex-col gap-1">
      <img
        src={logoSrc}
        alt="logo"
        style={{ imageRendering: "pixelated", maxWidth: 132 }}
      />
      {label}
    </h1>
  );
}

export default Logo;
