function ExercisesEmpty() {
  return (
    <div className="text-center p-4">
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900">
        ¡Ups! 😅 Aún no hay ejercicios aquí.
      </h1>
      <p className="text-base leading-7 text-gray-600">
        ¡Sube ejercicios y empieza a planificar clases! 🏋️‍♀️
      </p>
    </div>
  );
}

export default ExercisesEmpty;
