import clsx from "clsx";

function NumberFilter({ label, id, disabled, value = "", onChange }) {
  return (
    <div className="mb-4">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 bg-white capitalize"
      >
        {label}
      </label>
      <div className="mt-2 rounded-lg z-0">
        <input
          type="number"
          name={id}
          id={id}
          min="0"
          className={clsx(
            [
              "block w-full rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm",
            ],
            { "cursor-not-allowed bg-gray-50": disabled }
          )}
          disabled={disabled}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>
    </div>
  );
}

export default NumberFilter;
