function ExercisesNotFound() {
  return (
    <div className="text-center p-4">
      <h1 className="mb-4 text-2xl font-bold tracking-tight text-gray-900">
        ¡Uy! 😯 No se encontraron ejercicios.
      </h1>
      <p className="text-base leading-7 text-gray-600">
        ¡Inténtalo de nuevo con otros filtros! 🕵️‍♀️
      </p>
    </div>
  );
}

export default ExercisesNotFound;
