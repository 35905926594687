import { Navigate, Route, Routes } from "react-router-dom";

import UploadExercises from "../admin/upload-exercises/UploadExercises";
import Login from "../auth/Login";
import RequireAuth from "../auth/RequireAuth";
import Layout from "../layout/Layout";
import SearchExercises from "../search-exercises/SearchExercises";

import NotFound from "./NotFound";

function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<SearchExercises />} />
        <Route path="/upload-exercises" element={<UploadExercises />} />
      </Route>
      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />
    </Routes>
  );
}

export default Router;
