import { useEffect, useState } from "react";

import { filterableProperties } from "../../models/exercises/exercisesSchema";
import { useExercisesRepository } from "../../repositories/exercises/exercisesRepository";

const defaultOptions = { viewMode: "grid", sort: "default" };

function computeFilterableWithOptions(exercises) {
  let uniqueOptions = {};

  for (const exercise of exercises) {
    for (const property of filterableProperties) {
      const key = property.id;
      const value = exercise[key];
      if (property.filterType !== "array" || !value) {
        continue;
      }

      if (!uniqueOptions[key]) {
        uniqueOptions[key] = new Set();
      }

      for (const element of value) {
        uniqueOptions[key].add(element);
      }
    }
  }

  return filterableProperties.map((property) => {
    if (uniqueOptions[property.id]) {
      return {
        ...property,
        options: Array.from(uniqueOptions[property.id]).sort(),
      };
    }
    return property;
  });
}

function matchesFilterCriteria(row, filter) {
  const rowValue = row[filter.id];

  if (filter.filterType === "array") {
    return filter.value.every((item) => rowValue?.includes(item));
  } else {
    const normalizedFilterValue = filter.value.toLowerCase();
    return String(rowValue).includes(normalizedFilterValue);
  }
}

function useSearchExercises() {
  const [filters, setFilters] = useState(filterableProperties);
  const { loading, exercises } = useExercisesRepository();
  const [filteredExercises, setFilteredExercises] = useState(null);
  const [options] = useState(defaultOptions);

  function setFilter(key, value) {
    const filterIndex = filterableProperties.findIndex(({ id }) => id === key);

    const newFilters = [...filters];

    newFilters[filterIndex] = {
      ...newFilters[filterIndex],
      value,
    };

    setFilters(newFilters);

    let exercisesUpdated = [...exercises];

    for (const filter of newFilters) {
      if (filter.value) {
        exercisesUpdated = exercisesUpdated.filter((row) =>
          matchesFilterCriteria(row, filter)
        );
      }
    }

    setFilteredExercises(exercisesUpdated);
  }

  useEffect(() => {
    if (exercises === null) {
      setFilters(filterableProperties);
      setFilteredExercises(null);
    } else {
      setFilters(computeFilterableWithOptions(exercises));
      setFilteredExercises(exercises);
    }
  }, [exercises]);

  return {
    loading: loading || !filteredExercises,
    filters,
    setFilter,
    filteredExercises,
    exercises,
    options,
  };
}

export { useSearchExercises };